<template>
  <div>
    <div class="main-user">
      <el-container>
        <el-header class="main-user-header">
          <div>
          </div>
          <div class="main-user-header-buttion">
            <el-button round type="primary" icon="el-icon-circle-plus-outline" size="small" @click="add(null)">新增角色</el-button>
          </div>
        </el-header>
        <el-main class="main-center">
          <div class="main-table">
            <el-table :data="tableData" stripe style="width: 100%"  size="small">
              <el-table-column align="center" prop="roleName" label="角色名称">
              </el-table-column>
              <el-table-column align="center" prop="createDate" label="创建日期">
              </el-table-column>
              <el-table-column align="center" prop="updateDate" label="更新日期">
              </el-table-column>
              <el-table-column label="操作" align="center" width="400">
                <template slot-scope="props">
                  <span v-for="arr in buttionArr" class="user-span" :hidden="arr.code=='del' &&props.row.roleName=='超级管理员'">
                    <el-button round :type="arr.styleDisplay" :icon="arr.icon" size="mini" @click="opeate(arr,props.row)">{{arr.menuName}}</el-button>
                  </span>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="main-page">
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="pageSizeArr" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total">
            </el-pagination>
          </div>
        </el-main>
      </el-container>

    </div>
    <!-- Form -->

    <el-dialog :title="title" :visible.sync="dialogFormVisible" width="400px">
      <el-form :model="from" :rules="rules" ref="from" label-width="100px" class="demo-ruleForm" label-position="left">
        <el-form-item label="角色名" prop="roleName">
          <el-input v-model="from.roleName"></el-input>
        </el-form-item>

        <el-form-item style="text-align: right; margin: 0">
          <el-button type="primary" @click="submitForm('from')">提交</el-button>
          <el-button @click="resetForm('ruleForm')">重置</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    <el-dialog title="角色关联菜单" :visible.sync="dialogRoleVisible" width="400px">
      <el-form label-width="100px" class="demo-ruleForm" label-position="left">

        <el-tree :data="roleArray" show-checkbox node-key="id" ref="tree" :default-expanded-keys="[2, 3]" :default-checked-keys="checkedKeys" :props="defaultProps">
        </el-tree>

        <el-form-item style="text-align: right; margin: 0">
          <el-button type="primary" @click="submitRoleForm()">提交</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

  </div>

</template>
<script>
export default {
  props: ["buttions"],
  data() {
    return {
      roleId: null,
      roleKey: [],
      tableData: null,
      total: 10,
      pageIndex: 1,
      pageSize: 10,
      pageSizeArr: [10, 20, 50, 100],
      currentPage: 1,
      buttionArr: null,
      dialogFormVisible: false,
      dialogRoleVisible: false,
      checkedKeys: [],
      
      from: { id: "", roleName: "" },
      title: "新增",
      rules: {
        roleName: [
          { required: true, message: "请输入用户名", trigger: "blur" },
          {
            min: 1,
            max: 10,
            message: "长度在 1 到 10 个字符",
            trigger: "blur",
          },
        ],
      },
      roleArray: null,
      defaultProps: {
        children: "children",
        label: "label",
      },
    };
  },

  //生命周期 - 创建完成（访问当前this实例）
  created() {
    this.getPage();
    this.getMenuButtions();
  },
  mounted() {},
  computed: {},
  //生命周期 - 挂载完成（访问DOM元素）
  methods: {
    getPage() {
      this.page = { pageIndex: this.pageIndex, pageSize: this.pageSize };
      this.$post("/role/page", this.page).then((res) => {
        if (res.success && res.statusCode == 200) {
          this.tableData = res.data.records;
          this.total = res.data.total;
        }
      });
    },
    //获取权限关联按钮
    getMenuButtions() {
      this.buttionArr = JSON.parse(localStorage.getItem("menuButtions"));
      
    },
    add(row) {
      this.dialogFormVisible = true;
      this.title = "新增";
      if (this.$refs["from"] !== undefined) {
        this.$refs["from"].resetFields();
      }
      this.from.roleName = "";
      this.from.id = 0;
    },
    edit(row) {
      console.log(row);
      this.dialogFormVisible = true;
      this.title = "编辑";
      this.from.id = row.id;
      this.from.roleName = row.roleName;
    },
    del(row) {
      this.$confirm("是否删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$del("/role/delete", { id: row.id }).then((res) => {
          if (res.success && res.statusCode == 200) {
           
            this.getPage();
          }
        });
      });
    },
    linkMenu(row) {
      this.dialogRoleVisible = true;
      this.roleKey = [];
      this.roleId = row.id;
      console.log(row);

      this.$get("/menu/menuList", { roleId: this.roleId }).then((res) => {
        if (res.success && res.statusCode == 200) {
          this.roleArray = res.data;
          this.checkedKeys = [];
          
          //获取选中的值
          this.roleArray.forEach((item1) => {
            if (item1.isSelected) {
              this.checkedKeys.push(item1.id);
            }
            if (item1.children.length > 0) {           
              item1.children.forEach((item2) => {
                if (item2.isSelected) {
                  this.checkedKeys.push(item2.id);
                }
                if (item2.children.length > 0) {                 
                  item2.children.forEach((item3) => {
                    if (item3.isSelected) {
                      this.checkedKeys.push(item3.id);
                    }
                  });
                }
              });
            }
          });
          console.log(this.checkedKeys);
          
        }
      });
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.from.id == null) {
            this.from.id == 0;
          }

          if (this.from.id == 0) {
            this.$post("/role/add", this.from).then((res) => {
              if (res.success && res.statusCode == 200) {
                
                this.getPage();
              }
              this.dialogFormVisible = false;
            });
          } else {
            this.$post("/role/edit", this.from).then((res) => {
              if (res.success && res.statusCode == 200) {
                ;
                this.getPage();
              }
              this.dialogFormVisible = false;
            });
          }
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getPage();
    },
    handleCurrentChange(val) {
      this.pageIndex = val;
      this.getPage();
    },
    opeate(row, item) {
      //编辑
      if (row.code == "edit") {
        this.edit(item);
      }
      //删除
      else if (row.code == "del") {
        this.del(item);
      }
      //用户关联角色
      else if (row.code == "menu") {
        this.linkMenu(item);
      }
    },
    submitRoleForm() {
      let arr = this.$refs.tree.getCheckedKeys();
  
      console.log(arr)
      var json = { id: this.roleId, list: arr };
      this.$post("/role/roleLinkMenu", json).then((res) => {
        if (res.success && res.statusCode == 200) {
        
          this.getPage();
        }
      });
      this.dialogRoleVisible = false;
    }
      
  },
};
</script>
<style lang="scss" scoped>
.main-user .el-main {
  padding: 5px;
}
.main-user .el-header {
  padding: 5px;
}
.main-user-header-buttion {
  margin-right: 20px;
}
.main-user-header {
  align-items: center;
  vertical-align: middle;
  display: flex;
  justify-content: space-between;
}
.main-center .main-page {
  float: right;
}
.user-span {
  margin-left: 10px;
}
</style>
